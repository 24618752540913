import { TrackingCategories } from '@/models/trackingModel';
import { IHeroBannerProps } from '@/components/HeroBanner';

export const HERO_BANNER: IHeroBannerProps = {
  content: {
    isHero: true,
    includeLogo: true,
    trackingCategory: TrackingCategories.About,
    subtitle: 'largestW2DigitalWorkPlatform'
  },
  img: {
    src: 'about-animate-image.png',
    alt: 'about-animate'
  }
}


export const PLATFORM_LIST = [
  'positionsFilledEachDay',
  'registeredWorkers',
  'businessUsers',
  'states',
  'multiLingual',
  'markets'
];

export const TECH_LIST = [
    'microservices',
    'releases',
    'compliance',
    'machineLearning'
];
