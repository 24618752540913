import * as React from 'react';
import { INavLink } from '@/models/navLinks';
import Link from '../Link';
import { useTrackingHook } from '@/hooks/useTrackingHook';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import {
    TrackingCategoryType,
    TrackingNames
} from '@/models/trackingModel';
import './styles.scss';


interface INavigationProps {
    items: INavLink[];
    isActive: boolean;
    trackingType: TrackingCategoryType;
    children?: React.ReactNode;
    navClickCallBack: () => void;
}

export const Navigation: React.FC<INavigationProps> = ({
                                                           items,
                                                           isActive,
                                                           trackingType,
                                                           children,
                                                           navClickCallBack
                                                       }) => {

    const { trackClick } = useTrackingHook();
    const { translate } = useTranslationHook();

    const handleClick = () => {
        trackClick(trackingType, TrackingNames.NavHomeClick);
        navClickCallBack();
    };

    return <nav className={'navigation' + (isActive ? ` active` : '')}>
        <div className='navigation__wrapper'>
            {items ? <ul>{items.map((item, index) => {
                return <li key={index}>
                    <Link to={item.link}
                          excludeBaseClassName={true}
                          onClick={() => {
                              handleClick();
                          }}
                    >
                        {translate(item.title)}
                    </Link>
                </li>;
            })}</ul> : null}
            {children}
        </div>
    </nav>;

};