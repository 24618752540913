import * as React from 'react';
import './styles.scss';
import { AnimatedComponent } from '../AnimatedComponent';
import {
    ContentCol,
    IContentColProps
} from '../ContentCol';
import {
    IImageColProps,
    ImageCol
} from '../ImageCol';
import { AppStoreLink } from '../AppStoreLink';
import {
    TrackingCategoryType,
    TrackingNameType
} from '@/models/trackingModel';

export interface IHeroBannerProps {
    content: IContentColProps;
    img: IImageColProps;
    appLinks?: {
        type: string;
        externalLink: string;
        trackingName?: TrackingNameType;
        trackingCategory?: TrackingCategoryType;
    }[];
}

export const HeroBanner: React.FC<IHeroBannerProps> = ({
                                                           content,
                                                           img,
                                                           appLinks
                                                       }) => {

    const outputAppBtns = () => {

        if (!appLinks) return <React.Fragment></React.Fragment>;
        return <div className='hero-banner__app-btns'>
            {appLinks.map((item, index) => {
                return <AppStoreLink
                    type={item.type}
                    externalLink={item.externalLink}
                    trackingName={item.trackingName}
                    trackingCategory={item.trackingCategory}
                    useButton={true}
                    key={index}
                />;
            })}
        </div>;
    };

    return <AnimatedComponent>
        <div className={'hero-banner' + (appLinks ? ' hero-banner--app-btns' : '')}>
            <div className='hero-banner__inner'>
                <div className='hero-banner__content'>
                    <ContentCol
                        {...content}
                    />
                    {outputAppBtns()}
                </div>
                <div className='hero-banner__img'>
                    <ImageCol
                        {...img}
                    />
                </div>
            </div>
        </div>
    </AnimatedComponent>;
};