import TranslationService, {
    ITranslationService
} from '@/services/TranslationService';

export const useTranslationHook = () => {

    const translationService: ITranslationService = TranslationService.getInstance();

    const translate = (key: string) => translationService.translate(key);

    const getLanguages = () => translationService.getLanguages();
    const getLanguage = () => translationService.getLanguage();
    const changeLang = async (lang: string) => translationService.changeLanguageAsync(lang);
    const translateObject = (key: string) => translationService.translateObject(key);

    return {
        translate,
        getLanguages,
        getLanguage,
        changeLang,
        translateObject
    };

};