import * as React from 'react';
import InfoTilesView from '@/controllers/InfoTilesView';
import ContactUsView from '@/controllers/ContactUsView';
import { TrackingCategories } from '@/models/trackingModel';
import { HeroBanner } from '@/components/HeroBanner';
import * as ABOUT from '@/data/pages/about';
import { useTrackingHook } from '@/hooks/useTrackingHook';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import {
    IMAGE_CONTENT_COL_SPACING,
    ImageContentCol
} from '@/components/ImageContentCol';
import { ImageCol } from '@/components/ImageCol';
import { ContentCol } from '@/components/ContentCol';

export const About = () => {

    const { trackPage } = useTrackingHook();
    const {
        translate,
        translateObject
    } = useTranslationHook();

    React.useEffect(() => {
        trackPage(TrackingCategories.About);
    }, [trackPage]);

    const listContent: any = translateObject('virtualStaffingCompanyItems');

    return (
        <div id='top'
             className='about-page'>
            <HeroBanner {...ABOUT.HERO_BANNER} />
            <InfoTilesView
                title={translate('ourPlatform')}
                tileList={ABOUT.PLATFORM_LIST}
            />
            <ImageContentCol
                spacing={IMAGE_CONTENT_COL_SPACING.NONE}
                leftContent={<ContentCol
                    title={[
                        { text: 'virtualStaffingCompany' }
                    ]}
                    listContent={listContent}

                    trackingCategory={TrackingCategories.About}
                />}
                rightContent={<ImageCol src={'laptop-animate-image.png'} alt={'laptop-animate'}/>}
            />
            <InfoTilesView
                title={translate('ourTechnology')}
                tileList={ABOUT.TECH_LIST}
            />
            <div id='contact-us'>
                <ContactUsView
                    isMessageRequired={true}
                    trackingCategory={TrackingCategories.About}
                />
            </div>
        </div>
    );

};

export default About;
