import React from 'react';
import './styles.scss';

interface Props {
    title: string;
    disabled?: boolean;
    onPress?: Function;
    whiteBorder?: boolean;
}

export class Button extends React.Component<Props> {
    render() {
        const {
            disabled,
            title,
            onPress,
            whiteBorder
        } = this.props;

        return (
            <button className={`button ${disabled ? 'button--disabled' : ''} ${whiteBorder ? 'button--white' : ''}`}
                    onClick={() => onPress && onPress()}>
                {title}
            </button>
        );
    }
}

export default Button;