import React from 'react';
import Modal from 'react-modal';
import './styles.scss';

export interface Props {
    title: string;
    subtitle: string;
    isOpen: boolean;
    isError?: boolean;
    closeModal: Function;
}

export class ModalView extends React.Component<Props> {
    render() {
        const {
            title,
            subtitle,
            isOpen,
            closeModal,
            isError
        } = this.props;

        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: '20px',
                border: 'none'
            }
        };

        return (
            <div>
                <Modal isOpen={isOpen}
                       style={customStyles}>
                    <div className='header'>
                        <h6 className={'title' + `${isError ? ' error' : ''}`}>
                            {title}
                        </h6>
                    </div>
                    <div className='sj-modal-content'>
                        <div className='subtitle'>
                            {subtitle}
                        </div>
                        <button
                            className='button'
                            onClick={() => {
                                closeModal();
                            }}
                        >
                            Close
                        </button>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default ModalView;
