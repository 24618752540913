import * as React from 'react';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import ContactUsView from '@/controllers/ContactUsView';
import { TrackingCategories } from '@/models/trackingModel';

const Contact = () => {
    const { translate } = useTranslationHook();

    return (
        <div id='contact-us'>
            <ContactUsView
                isMessageRequired={true}
                trackingCategory={TrackingCategories.About}
            />
        </div>
    );
};
export default Contact;