import * as React from 'react';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import { AnimatedComponent } from '../AnimatedComponent';
import {
    ContentCol,
    IContentColProps
} from '../ContentCol';
import {
    Icon,
    ICON_TYPES
} from '../Icons';
import { IImageColProps } from '../ImageCol';
import { AppStoreLink } from '../AppStoreLink';
import {
    TrackingCategoryType,
    TrackingNameType
} from '@/models/trackingModel';
import { externalLinks } from '@/models/external-links';
import './styles.scss';

export interface IHeroBannerProps {
    content: IContentColProps;
    img: IImageColProps;
    appLinks?: {
        type: string;
        externalLink: string;
        trackingName?: TrackingNameType;
        trackingCategory?: TrackingCategoryType;
    }[];
}

export const PartnerBanner: React.FC<IHeroBannerProps> = ({
                                                              content,
                                                              img,
                                                              appLinks
                                                          }) => {
    const { translate } = useTranslationHook();

    const outputAppBtns = () => {

        if (!appLinks) return <React.Fragment></React.Fragment>;
        return <div className='partner-banner__app-btns'>
            {appLinks.map((item, index) => {
                return <AppStoreLink
                    type={item.type}
                    externalLink={item.externalLink}
                    trackingName={item.trackingName}
                    trackingCategory={item.trackingCategory}
                    useButton={true}
                    key={index}
                />;
            })}
        </div>;
    };

    return <AnimatedComponent>
        <div className={'partner-banner' + (appLinks ? ' partner-banner--app-btns' : '')}>
            <div className='partner-banner__inner'>
                <div className='partner-banner__img'>
                    <img className='partner-banner__logo'
                         src={img.src}
                         alt={img.alt}/>
                </div>
                <div className='partner-banner__is-now'>
                    {translate('isNowPartOf')}
                </div>
                <div className='partner-banner__content'>
                    <a className='text-decoration-none text-reset' href={externalLinks.swipejobsWebsite}>
                        <Icon icon={ICON_TYPES.LOGO_LANDSCAPE}/>
                    </a>
                    <ContentCol
                        {...content}
                    />
                    {outputAppBtns()}
                </div>
            </div>
        </div>
    </AnimatedComponent>;
};