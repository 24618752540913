import * as React from 'react';
import { TrackingCategories } from '@/models/trackingModel';
import { useTrackingHook } from '@/hooks/useTrackingHook';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import { ContentBanner } from '@/components/ContentBanner';
import './styles.scss';

export const NotFound = () => {

    const { trackPage } = useTrackingHook();
    const { translate } = useTranslationHook();

    React.useEffect(() => {
        trackPage(TrackingCategories.NotFound);
    }, [trackPage]);

    return (
        <div id='top'
             className='not-found'>
            <ContentBanner content={[
                { text: 'notFoundTitle' }
            ]}/>
            <div className='component-wrapper'>
                <div className='component-wrapper__inner'>
                    <div className='not-found__body'>
                        <p>{translate('notFound')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
