import * as React from 'react';
import './styles.scss';
import reactStringReplace from 'react-string-replace';
import { ContentCol } from '@/components/ContentCol';
import { ImageContentCol } from '@/components/ImageContentCol';
import { PartnerBanner } from '@/components/PartnerBanner';
import * as HOME from '@/data/pages/partner-premier';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import { TrackingCategories } from '@/models/trackingModel';
import RenderParagraphs from '@/components/RenderParagraphs';

const full_partner_name = 'BrandPack Solutions';
const short_partner_name = 'BrandPack';

const BrandPack = () => {
    const {
        translate,
        translateObject
    } = useTranslationHook();

    const listContentLeft: any = translateObject('homeDotPointListLeft');
    const listContentRight: any = translateObject('homeDotPointListRight');

    return <div id='top' className='home-page'>
        <PartnerBanner
            {...HOME.PARTNER_BANNER}
            img={{
                src: '/images/brandpack.png',
                alt: 'brandpack'
            }}
        />
        <ImageContentCol
            leftContent={
                <ContentCol title={[
                    { text: translate('homeLeftTitle') }
                ]}
                            listContent={listContentLeft}
                            trackingCategory={TrackingCategories.BrandPack}
                />}
            rightContent={
                <ContentCol title={[
                    { text: translate('homeRightTitle') }
                ]}
                            listContent={listContentRight}
                            trackingCategory={TrackingCategories.BrandPack}
                />}
        />
        <p className='home-paragraph'>
            {reactStringReplace(translate('homeParagraphVariant'), '{0}', (match, i) => (
                <span>{full_partner_name}</span>
            ))}
        </p>
        <p className='home-paragraph'>
            {translate('homeParagraphVariantDescription')}
        </p>
        <h3 className='home-paragraph'>
            {translate('hiringTitle')}
        </h3>
        <p className='home-paragraph'>
            {reactStringReplace(translate('homeParagraphVariant'), '{0}', (match, i) => (
                <span>{short_partner_name}</span>
            ))}
        </p>
        <p className='home-paragraph'>
            {reactStringReplace(translate('homeParagraphVariantDescription'), '{0}', (match, i) => (
                <span>{short_partner_name}</span>
            ))}
        </p>
        <RenderParagraphs translateLabel='hiringParagraphs'/>

    </div>;
};
export default BrandPack;