import * as React from 'react';
import './styles.scss';
import { useTrackingHook } from '@/hooks/useTrackingHook';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import Link from '@/components/Link';
import {
    IFooterLinkProps,
    IFooterLinkBlock
} from '@/models/footerlinks';

export const FooterLinks: React.FC<IFooterLinkProps> = ({
                                                            title,
                                                            trackingType,
                                                            links
                                                        }) => {

    const { trackClick } = useTrackingHook();
    const { translate } = useTranslationHook();

    const checkItemLink = (item: IFooterLinkBlock): React.ReactNode => {

        const title = translate(item.title);

        if (item.link) {
            return <Link to={item.link}
                         onClick={() => trackClick(trackingType, item.trackingName)}>
                {title}
            </Link>;
        }

        if (item.external) {
            return <a href={item.external}
                      rel='noopener noreferrer'
                      onClick={() => trackClick(trackingType, item.trackingName)}
                      target='_blank'>
                {title}
            </a>;
        }

        return <React.Fragment></React.Fragment>;
    };

    return <div className='footer-links'>
        <h6 className='footer-links__title'>{translate(title)}</h6>
        {links ? <ul>{links.map((item, index) => {
            return <li key={index}>
                {checkItemLink(item)}
            </li>;
        })}</ul> : null}
    </div>;

};