import * as React from 'react';
import './styles.scss';
import { externalLinks } from '@/models/external-links';
import { useTrackingHook } from '@/hooks/useTrackingHook';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import {
    TrackingCategories,
    TrackingNames
} from '@/models/trackingModel';
import {
    Icon,
    ICON_TYPES
} from '../Icons';
import { Navigation } from '../Navigation';
import LanguageSelector from '../LanguageSelector';
import Link from '../Link';
import { useScrollDirection } from '@/hooks/useScrollDirection';
import { INavLink } from '@/models/navLinks';

interface IProps {
    headerLinks: INavLink[];
    logoType: ICON_TYPES;
}

export const HeaderBar = (props: IProps) => {

    const { trackClick } = useTrackingHook();
    const { translate } = useTranslationHook();
    const [hasLoaded, loadedState] = React.useState(false);
    const anchorRef = React.useRef(null);
    const scrollDir = useScrollDirection(anchorRef, {});
    const mobileBtn = React.useRef<HTMLButtonElement>(null);

    const [mobileIsOpen, toggleMobileMenu] = React.useState(false);
    const checkTrackingType = () => (window.innerWidth > 767 ? TrackingCategories.Navigation : TrackingCategories.SideNavigation);

    React.useEffect(() => {
        setTimeout(() => {
            loadedState(true);
        }, 100);
    }, []);

    const handleUserClick = (trackUserClick: boolean) => {

        if (trackUserClick) {
            trackClick(TrackingCategories.SideNavigation, (!mobileIsOpen ? TrackingNames.SideNavOpenClick : TrackingNames.SideNavOverlayCloseClick));
        }

        if (mobileBtn.current && mobileBtn.current.clientWidth) toggleMobileMenu(!mobileIsOpen);
    };

    const outputBusinessLoginBtn = () => {
        return <a href={externalLinks.customerDesktop}
                  rel='noopener noreferrer'
                  className='button'
                  target='_blank'
                  onClick={() => trackClick(TrackingCategories.Navigation, TrackingNames.NavBusinessSignInClick)}>
            {translate('businessSignIn')}
        </a>;
    };

    return <React.Fragment>
        <header className={'header-bar' + (scrollDir === 'down' ? ' scroll' : '') + (hasLoaded ? ' loaded' : '')}>
            <div className='header-bar__inner'>
                <div className='header-bar__left'>
                    <div className='header-bar__logo'>
                        <Link
                            to='/'
                            excludeBaseClassName={true}
                            onClick={() => trackClick(TrackingCategories.Navigation, TrackingNames.NavLogoClick)}
                        >
                            <span className='header-bar__logo-icon'><Icon icon={ICON_TYPES.LOGO_ICON}/></span>
                            <span className='header-bar__logo-text'><Icon icon={props.logoType}/></span>
                        </Link>
                    </div>
                    <div className='header-bar__nav-wrapper'>
                        <Navigation
                            isActive={mobileIsOpen}
                            trackingType={checkTrackingType()}
                            items={props.headerLinks}
                            navClickCallBack={() => {
                                handleUserClick(false);
                            }}
                        >
                            {outputBusinessLoginBtn()}
                            <LanguageSelector trackingCategory={checkTrackingType()}/>
                        </Navigation>
                    </div>
                </div>
                <div className='header-bar__right'>
                    {outputBusinessLoginBtn()}
                    <button
                        className={'header-bar__mobile-btn' + (mobileIsOpen ? ' active' : '')}
                        onClick={() => {
                            handleUserClick(true);
                        }}
                        ref={mobileBtn}
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span className='sr-only'>Open/Close mobile menu</span>
                    </button>
                </div>
            </div>
        </header>
        <div className='header-bar__anchor' aria-hidden={true} ref={anchorRef}></div>
    </React.Fragment>;
};