import React from 'react';
import TranslationService, {
    ITranslationService
} from '@/services/TranslationService';
import { isString } from 'lodash';
import reactStringReplace from 'react-string-replace';
import { externalLinks } from '@/models/external-links';
import { contactDetails } from '@/models/contact-details';
import TrackingService, {
    ITrackingService
} from '@/services/TrackingService';
import { TrackingCategories } from '@/models/trackingModel';
import './styles.scss';

export type ContentItemType = 'stringWithLink' |
    'unorderedList' |
    'orderedList' |
    'address';

export class ContentItemTypes {
    static readonly StringWithLink: ContentItemType = 'stringWithLink';
    static readonly UnorderedList: ContentItemType = 'unorderedList';
    static readonly OrderedList: ContentItemType = 'orderedList';
    static readonly Address: ContentItemType = 'address';
}

interface IPrivacyPolicyContentItem {
    type: string;
    items?: string[];
    string?: string;
    email?: string;
    link?: string;
}

interface IPrivacyPolicyItem {
    title?: string;
    items: (string | IPrivacyPolicyContentItem)[];
}

export interface Props {

}

export class PrivacyPolicy extends React.Component<Props> {
    private readonly translationService: ITranslationService;
    private readonly trackingService: ITrackingService;

    constructor(props: any) {
        super(props);
        this.translationService = TranslationService.getInstance();
        this.trackingService = new TrackingService();
        this.trackingService.trackPage(TrackingCategories.PrivacyPolicy);
    }

    private translate = (key: string) => this.translationService.translate(key);
    private translateObject = (key: string) => this.translationService.translateObject(key);

    private getItemList(): IPrivacyPolicyItem[] {
        const weCollectItems: any = this.translateObject('informationWeCollectItems');
        const weCollectListItems: any = this.translateObject('informationWeCollectListItems');
        const howWeShareYourInformationItems: any = this.translateObject('howWeShareYourInformationItems');
        const howWeShareYourInformationListItems: any = this.translateObject('howWeShareYourInformationListItems');
        const howWeUseItItems: any = this.translateObject('howWeUseItItems');
        const locationBasedServicesItems: any = this.translateObject('locationBasedServicesItems');
        const renderYourChoicesItems = () => {
            const yourChoicesPrivacyList: any = this.translateObject('yourChoicesPrivacyItems');
            const yourChoicesPrivacyItem = yourChoicesPrivacyList.map((item: string) => {
                const list = {
                    type: ContentItemTypes.StringWithLink,
                    string: item,
                    email: externalLinks.swipejobsPrivacy
                };
                return list;
            });
            const yourChoicesList = [
                this.translate('yourChoicesSubTitle'),
                {
                    type: ContentItemTypes.StringWithLink,
                    string: this.translate('yourChoicesSupportParagraph'),
                    email: externalLinks.swipejobsSupport
                }
            ];
            return yourChoicesList.concat(yourChoicesPrivacyItem);
        };
        const useOfCookiesList: any = this.translateObject('useOfCookiesItems');
        const informationProtectionItems: any = this.translateObject('informationProtectionItems');
        const retentionAndDeletionItems: any = this.translateObject('retentionAndDeletionItems');
        const retentionAndDeletionListItems: any = this.translateObject('retentionAndDeletionListItems');
        const paymentProcessorsItems: any = this.translateObject('paymentProcessorsItems');
        return [
            {
                items: [
                    {
                        type: ContentItemTypes.StringWithLink,
                        string: this.translate('mainItem1'),
                        link: externalLinks.swipejobsWebsite
                    },
                    this.translate('mainItem2')
                ]
            },
            {
                title: this.translate('informationWeCollectTitle'),
                items: weCollectItems.concat({
                    type: ContentItemTypes.UnorderedList,
                    items: weCollectListItems
                })
            },
            {
                title: this.translate('howWeUseItTitle'),
                items: howWeUseItItems
            },
            {
                title: this.translate('howWeShareYourInformationTitle'),
                items: howWeShareYourInformationItems.concat({
                    type: ContentItemTypes.OrderedList,
                    items: howWeShareYourInformationListItems
                })
            },
            {
                title: this.translate('locationBasedServicesTitle'),
                items: locationBasedServicesItems
            },
            {
                title: this.translate('digitalOnBoardingTitle'),
                items: [
                    this.translate('digitalOnBoardingPresentation'),
                    {
                        type: ContentItemTypes.StringWithLink,
                        string: this.translate('digitalOnBoardingContactUsEmail'),
                        email: externalLinks.swipejobsPrivacy
                    }
                ]
            },
            {
                title: this.translate('yourChoicesTitle'),
                items: renderYourChoicesItems()
            },
            {
                title: this.translate('useOfCookiesTitle'),
                items: useOfCookiesList
            },
            {
                title: this.translate('informationProtectionTitle'),
                items: informationProtectionItems
            },
            {
                title: this.translate('retentionAndDeletionTitle'),
                items: retentionAndDeletionItems.concat({
                    type: ContentItemTypes.OrderedList,
                    items: retentionAndDeletionListItems
                })
            },
            {
                title: this.translate('paymentProcessorsTitle'),
                items: paymentProcessorsItems
            },
            {
                title: this.translate('crossBorderTransfersTitle'),
                items: [
                    this.translate('crossBorderTransfersDescription')
                ]
            },
            {
                title: this.translate('californiaPrivacyRightsTitle'),
                items: [
                    {
                        type: ContentItemTypes.StringWithLink,
                        string: this.translate('californiaPrivacyRightsDescription'),
                        email: externalLinks.swipejobsPrivacy
                    }
                ]
            },
            {
                title: this.translate('childrenTitle'),
                items: [
                    this.translate('childrenDescription')
                ]
            },
            {
                title: this.translate('changesInThePolicyTitle'),
                items: [
                    this.translate('changesInThePolicyDescription')
                ]
            },
            {
                title: this.translate('businessTransfersTitle'),
                items: [
                    this.translate('businessTransfersDescription')
                ]
            },
            {
                title: this.translate('contactUsTitle'),
                items: [
                    this.translate('contactUsDescription'),
                    {
                        type: ContentItemTypes.Address,
                        items: [
                            contactDetails.addressLine1,
                            contactDetails.addressLine2,
                            contactDetails.addressLine3,
                            contactDetails.addressLine4
                        ]
                    },
                    {
                        type: ContentItemTypes.StringWithLink,
                        string: '{0}',
                        email: externalLinks.swipejobsInfo
                    }
                ]
            }

        ];
    }

    private getUnorderedList(items: string[]) {
        return (
            <ul>
                {
                    items.map((listItem: string, listItemIndex: number) => (
                        <li key={listItemIndex}>
                            {listItem}
                        </li>
                    ))
                }
            </ul>
        );
    }

    private getOrderedList(items: string[]) {
        return (
            <ol type='a'>
                {
                    items.map((listItem: string, listItemIndex: number) => (
                        <li key={listItemIndex}>
                            {listItem}
                        </li>
                    ))
                }
            </ol>
        );
    }

    private getAddress(items: string[]) {
        return (
            <div>
                {
                    items.map((listItem: string, listItemIndex: number) => (
                        <p key={listItemIndex}>
                            {listItem}
                        </p>
                    ))
                }
            </div>
        );
    }

    private getStringWithLink(item: IPrivacyPolicyContentItem) {
        const email = item.email ? <a href={`mailto:${item.email}`}>{item.email}</a> : null;
        const link = item.link ? (
            <a href={item.link}
               rel='noopener noreferrer'
               target='_blank'>
                {item.link}
            </a>
        ) : null;

        const stringWithLink = reactStringReplace(item.string, '{0}', (match, i) => (
            <span key={i}>{link || email}</span>
        ));
        return <p>{stringWithLink}</p>;
    }

    private getContentItems(item: (string | IPrivacyPolicyContentItem), index: number) {
        return (
            <div key={index}>
                {isString(item) && <p>{item}</p>}
                {
                    !isString(item) &&
                    <div>
                        {item.type === ContentItemTypes.StringWithLink && this.getStringWithLink(item)}
                        {item.type === ContentItemTypes.UnorderedList && item.items && this.getUnorderedList(item.items)}
                        {item.type === ContentItemTypes.OrderedList && item.items && this.getOrderedList(item.items)}
                        {item.type === ContentItemTypes.Address && item.items && this.getAddress(item.items)}
                    </div>
                }
            </div>
        );
    }

    private getItems(item: IPrivacyPolicyItem, index: number) {
        return (
            <div key={index}>
                {
                    item.title &&
                    <h4 className='item-title'>
                        {item.title}
                    </h4>
                }
                {
                    item.items.map((contentItem: (string | IPrivacyPolicyContentItem), contentItemIndex: number) =>
                        this.getContentItems(contentItem, contentItemIndex))
                }
            </div>
        );
    }

    render() {
        const items: IPrivacyPolicyItem[] = this.getItemList();

        return (
            <div id='top'
                 className='privacy-policy-page component-wrapper'>
                <div className='component-wrapper__inner'>
                    <div className='privacy-policy-wrapper'>
                        <h1 className='title'>
                            {this.translate('privacyTitle')}
                        </h1>
                        <div>
                            {items.map((item: IPrivacyPolicyItem, index: number) => this.getItems(item, index))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicy;