import * as React from 'react';
import { useTranslationHook } from '@/hooks/useTranslationHook';

interface ParagraphProps {
    translateLabel: string;
    className?: string;
}

const RenderParagraphs = ({
                              translateLabel,
                              className
                          }: ParagraphProps) => {
    const { translateObject } = useTranslationHook();
    const paragraphs: any = translateObject(`${translateLabel}`);

    const render = paragraphs.map((item: string) => {
        return (
            <p className={className}>
                {item}
            </p>
        );
    });

    return (
        <>
            {render}
        </>
    );
};

export default RenderParagraphs;