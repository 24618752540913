import axios from 'axios';
import { externalLinks } from '@/models/external-links';

export interface IHttpService {
    get(url: string, params: any): Promise<any>;
    post(url: string, body: any, isSwipejobs?: any): Promise<any>;
}

export default class HttpService implements IHttpService {
    async get(url: string, params: any = {}) {
        return await axios.get(url, params);
    }

    async post(url: string, data: any = {}, isSwipejobs?: boolean): Promise<any> {
        let Authorization = null;

        if (isSwipejobs) {
            const guestToken = await this.getGuestToken();
            Authorization = `Bearer ${guestToken}`;
        }

        const headers = {
            Accept: 'application/json',
            Authorization
        };

        return axios({
            method: 'POST',
            url,
            data,
            headers
        });

    }

    private async getGuestToken() {
        const isProd = window.location.origin === externalLinks.swipejobsWebsite;
        const urlPrefix = isProd ? externalLinks.sjProdUrlPrefix : externalLinks.sjPreProdUrlPrefix;
        const guestTokenUrl = `${urlPrefix}${externalLinks.sjGuestToken}`;
        const guestTokenObject = await this.get(guestTokenUrl);
        return guestTokenObject?.data?.token;
    }
}
