import * as React from 'react';
import { getImageUrl } from '@/utils';
import './styles.scss';

export interface IImageColProps {
    src: string;
    alt: string;
}

export const ImageCol: React.FC<IImageColProps> = ({
                                                       src,
                                                       alt
                                                   }) => {
    return (
        <div className='image-col'>
            <img src={getImageUrl(src)} alt={alt}/>
        </div>
    );
};
