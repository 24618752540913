import * as React from 'react';
import './styles.scss';
import { useTrackingHook } from '@/hooks/useTrackingHook';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import {
  TrackingCategoryType,
  TrackingNameType,
} from '@/models/trackingModel';
import { getImageUrl } from '@/utils';

interface IStoreLinkProps {
  type: string;
  externalLink: string;
  useButton?: boolean;
  trackingName?: TrackingNameType;
  trackingCategory?: TrackingCategoryType;
}

export const AppStoreLink: React.FC<IStoreLinkProps> = ({
  type,
  externalLink,
  useButton,
  trackingName,
  trackingCategory,
}) => {
  const { trackClick } = useTrackingHook();
  const { translate } = useTranslationHook();

  const outputText = () => {
    return translate(type === 'ios' ? 'appStore' : 'googlePlayStore');
  };

  const outputBtn = () => {
    return type === 'ios' ? (
      <img src={getImageUrl('apple-badge.png')} alt='app-store-link' />
    ) : (
      <img src={getImageUrl('google-badge.png')} alt='google-play-store-link' />
    );
  };

  const btnOrLink = () => {
    return useButton ? outputBtn() : outputText();
  };

  const handleClick = () => {
    if (trackingName && trackingCategory)
      trackClick(trackingCategory, trackingName);
  };

  return (
    <a
      href={externalLink}
      className={'app-link' + (useButton ? ' app-link--btn' : '')}
      rel='noopener noreferrer'
      target='_blank'
      onClick={() => {
        handleClick();
      }}
    >
      {btnOrLink()}
    </a>
  );
};
