import {
    TrackingActionTypes,
    TrackingCategoryType,
    TrackingNameType
} from '@/models/trackingModel';

export interface ITrackingService {
    trackClick(category: TrackingCategoryType, name: TrackingNameType, value?: number): void;
    trackPage(page: TrackingCategoryType): void;
}

export default class TrackingService implements ITrackingService {
    trackClick(category: TrackingCategoryType, name: TrackingNameType, value?: number): void {
        (window as any)?._paq?.push(['trackEvent', category, TrackingActionTypes.ClickEvent, name, value]);
    }

    trackPage(category: TrackingCategoryType): void {
        (window as any)?._paq?.push(['trackEvent', category, TrackingActionTypes.PageEvent]);
    }
}