import i18n from 'i18next';
import _ from 'lodash';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import './App.scss';

import ActivePage from '@/components/ActivePage';
import Footer from '@/components/Footer';
import { HeaderBar } from '@/components/HeaderBar';
import { ICON_TYPES } from '@/components/Icons';

import { FOOTER_SOCIAL_LINKS } from '@/models/social';
import PreloadService, { IPreloadService } from '@/services/PreloadService';

import TranslationService from '@/services/TranslationService';
import NAV_LINKS from '@/models/navLinks';
import { FOOTER_LINKS } from '@/models/footerlinks';
import { pages } from '@/models/pages-routes';

export interface Props {
}

export interface State {
    loaded: boolean;
}

export class App extends React.Component<Props, State> {
    private preloadService: IPreloadService;

    constructor(props: any) {
        super(props);
        this.preloadService = new PreloadService();
        this.state = { loaded: false };
        this.initAsync();
    }

    private async initAsync() {
        this.preloadService.preloadImages();
        await TranslationService.getInstance().init();
        i18n.on('languageChanged', () => this.forceUpdate());
        this.setState({ loaded: true });
    }

    render() {
        if (!this.state.loaded) {
            return <div/>;
        }

        return (
            <div className='App'>
                <Router>
                    <HeaderBar headerLinks={NAV_LINKS}
                               logoType={ICON_TYPES.LOGO_TEXT}
                    />
                    <ActivePage pages={pages}/>
                    <Footer footerIcon={ICON_TYPES.LOGO_TEXT}
                            footerLinks={FOOTER_LINKS}
                            footerSocial={FOOTER_SOCIAL_LINKS}
                    />
                </Router>
            </div>
        );
    }
}

export default App;
