import React from 'react';
import reactStringReplace from 'react-string-replace';
import { AnimatedComponent } from '@/components/AnimatedComponent';
import Button from '@/components/Button';
import { ImageCol } from '@/components/ImageCol';
import {
    ImageContentCol,
    IMAGE_CONTENT_COL_SPACING
} from '@/components/ImageContentCol';
import { externalLinks } from '@/models/external-links';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import { full_partner_name } from '@/models/name';
import RenderParagraphs from '@/components/RenderParagraphs';

const Jobs = () => {
    const { translate } = useTranslationHook();

    return (
        <div className='mt-4' id='top'>
            <ImageContentCol
                title={translate('jobsTitle')}
                spacing={IMAGE_CONTENT_COL_SPACING.NONE}
                leftContent={
                    <>
                        <p>{reactStringReplace(translate('jobsParagraphVariant'), '{0}', (match, i) => (
                            <span>{full_partner_name}</span>
                        ))}</p>
                        <RenderParagraphs translateLabel='jobsParagraphsLeft'/>
                    </>
                }
                rightContent={<ImageCol src={'jobs-poster.png'} alt={'standing-workers'}/>}
            />
            <AnimatedComponent threshold={0.6}>
                <div>
                    <RenderParagraphs translateLabel='jobsParagraphsBottom'
                                      className='home-paragraph'/>
                    <div className='d-flex justify-content-center my-4'>
                        {/* TODO: Add APPLICANT_LANDING_URL  */}
                        <Button title={translate('downloadTheApp')} onPress={() => window.open(externalLinks.workerLandingDownload)}/>
                    </div>
                </div>
            </AnimatedComponent>
        </div>
    );
};

export default Jobs;