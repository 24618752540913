import * as React from 'react';

export const useScrollDirection = (anchorElementRef: React.RefObject<Element>,
                                   {
                                       threshold = 0,
                                       root = null,
                                       rootMargin = '0%'
                                   }) => {

    const [scrollDirection, setDirection] = React.useState('');

    const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {

        if (entry.boundingClientRect.y === 0 && scrollDirection !== 'up') {
            setDirection('up');
        } else {
            setDirection('down');
        }
    };

    React.useEffect(() => {
        const node = anchorElementRef?.current;
        const observerParams = {
            threshold,
            root,
            rootMargin
        };
        const observer = new IntersectionObserver(updateEntry, observerParams);
        if (!node) return;
        observer.observe(node);
        return () => observer.disconnect();
    }, [anchorElementRef, threshold, root, rootMargin]);

    return scrollDirection;

};