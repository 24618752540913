import * as React from 'react';
import { uniqueId } from 'lodash';
import './styles.scss';

export interface Props {
    value: string;
    placeholder: string;
    onChange: Function;
    checkValidation: Function;
    submitClicked: boolean;
}

class InputWithValidation extends React.Component<Props> {

    private inputId = uniqueId();

    render() {
        const {
            value,
            placeholder,
            onChange,
            checkValidation
        } = this.props;

        return (
            <div className='input-with-validation'>
                <label htmlFor={this.inputId}>{placeholder}</label>
                <input
                    id={`${this.inputId}-text-input`}
                    className={'form-input' + (!checkValidation(value) ? ' error' : '')}
                    type='text'
                    value={value}
                    onChange={(value) => onChange(value?.target?.value)}
                    placeholder={placeholder}
                />
            </div>
        );
    }
}

export default InputWithValidation;
