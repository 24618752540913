import * as React from 'react';
import './styles.scss';
import reactStringReplace from 'react-string-replace';
import { ContentCol } from '@/components/ContentCol';
import { ImageContentCol } from '@/components/ImageContentCol';
import { PartnerBanner } from '@/components/PartnerBanner';
import * as HOME from '@/data/pages/partner-premier';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import { TrackingCategories } from '@/models/trackingModel';
import {
    full_partner_name,
    short_partner_name
} from '@/models/name';
import RenderParagraphs from '@/components/RenderParagraphs';

const FirstClassStaffing = () => {
    const {
        translate,
        translateObject
    } = useTranslationHook();
    const listContentLeft: any = translateObject('homeDotPointListLeft');
    const listContentRight: any = translateObject('homeDotPointListRight');

    return <div id='top' className='home-page'>
        <PartnerBanner
            {...HOME.PARTNER_BANNER}
            img={{
                src: '/images/1stclassstaffing.png',
                alt: '1st Class Staffing'
            }}
        />
        <ImageContentCol
            leftContent={<ContentCol
                title={[
                    { text: translate('homeLeftTitle') }
                ]}
                listContent={listContentLeft}
                trackingCategory={TrackingCategories.FirstClassStaffing}
            />}
            rightContent={<ContentCol
                title={[
                    { text: translate('homeRightTitle') }
                ]}
                listContent={listContentRight}
                trackingCategory={TrackingCategories.FirstClassStaffing}
            />}
        />
        <p className='home-paragraph'>{reactStringReplace(translate('homeParagraphVariant'), '{0}', (match, i) => (
            <span>{full_partner_name}</span>
        ))}</p>
        <p className='home-paragraph'>{translate('homeParagraphVariantDescription')}</p>
        <h3 className='home-paragraph'>{translate('hiringTitle')}</h3>
        <p className='home-paragraph'>{reactStringReplace(translate('homeParagraphVariant'), '{0}', (match, i) => (
            <span>{short_partner_name}</span>
        ))}</p>
        <p className='home-paragraph'>{reactStringReplace(translate('homeParagraphVariantDescription'), '{0}', (match, i) => (
            <span>{short_partner_name}</span>
        ))}</p>
        <RenderParagraphs translateLabel='hiringParagraphs'/>


    </div>;
};
export default FirstClassStaffing;