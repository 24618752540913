import * as React from 'react';
import './styles.scss';
import { ContentCol } from '@/components/ContentCol';
import { ImageCol } from '@/components/ImageCol';
import {
    ImageContentCol,
    IMAGE_CONTENT_COL_SPACING
} from '@/components/ImageContentCol';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import { TrackingCategories } from '@/models/trackingModel';
import RenderParagraphs from '@/components/RenderParagraphs';

const Philosophy = () => {
    const {
        translate,
        translateObject
    } = useTranslationHook();

    const philosophyTopLeftDotPoints: any = translateObject('philosophyTopLeftDotPoints');
    const philosophyTopRightDotPoints: any = translateObject('philosophyTopRightDotPoints');
    const philosophyBottomLeftDotPoints: any = translateObject('philosophyBottomLeftDotPoints');
    const philosophyBottomRightDotPoints: any = translateObject('philosophyBottomRightDotPoints');

    return (
        <div id='top' className='home-page'>
            <div className='top-bullet-points-container'>
                <ImageContentCol
                    spacing={IMAGE_CONTENT_COL_SPACING.NONE}
                    title={translate('philosophy')}
                    leftContent={
                        <ContentCol listContent={philosophyTopLeftDotPoints}
                                    trackingCategory={TrackingCategories.Philosophy}/>
                    }
                    rightContent={
                        <ContentCol listContent={philosophyTopRightDotPoints}
                                    trackingCategory={TrackingCategories.Philosophy}/>
                    }
                />
            </div>

            <ImageContentCol spacing={IMAGE_CONTENT_COL_SPACING.NONE}
                             leftContent={
                                 <RenderParagraphs translateLabel='philosophyParagraphLeftList'/>
                             }
                             rightContent={
                                 <ImageCol src={'partner-philosophy.png'}
                                           alt={'philosophy'}/>
                             }/>

            <p className='home-paragraph'>
                {translate('philosophyParagraph')}
                <i>{translate('philosophyParagraphItalicPart')}</i>
            </p>
            <p className='home-paragraph'>
                <i>{translate('philosophyItalicPhrase')}</i>
            </p>
            <ImageContentCol leftContent={
                <ContentCol listContent={philosophyBottomLeftDotPoints}
                            trackingCategory={TrackingCategories.Philosophy}/>
            }
                             rightContent={
                                 <ContentCol listContent={philosophyBottomRightDotPoints}
                                             trackingCategory={TrackingCategories.Philosophy}/>
                             }
            />
        </div>
    );
};
export default Philosophy;
