import React from 'react';
import { Spinner } from '@blueprintjs/core';
import './styles.scss';

export interface Props {
    title: string;
    isOpen: boolean;
}

export class Loader extends React.Component<Props> {
    render() {
        const {
            isOpen,
            title
        } = this.props;

        return (
            <div>
                {
                    isOpen &&
                    <div className='loader-overlay'>
                        <div className='loader'>
                            <Spinner size={50}/>
                            {title}
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Loader;
