import React from 'react';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import {
    ICON_TYPES,
    Icon
} from '../Icons';
import './styles.scss';

interface ITileProps {
    title: string;
    content: string;
    clickable: boolean;
}

export const Tile: React.FC<ITileProps> = ({ clickable, title, content }) => {

    const { translate } = useTranslationHook();

    return (
        <div className={clickable ? 'tileButton' : 'tile'}>
            <div className='tile__content'>
                {translate(content)}
            </div>
            <div className='tile__title'>
                {translate(title)}
            </div>
            <div className='tile__icon'>
                <Icon icon={ICON_TYPES.LOGO_ICON}/>
            </div>
        </div>
    );

};
