import * as React from 'react';
import { useIntersectionObserver } from '@/hooks/useIntersectionObserver';

interface IAnimatedComponentProps {
    threshold?: number;
    children: JSX.Element;
}

export const AnimatedComponent: React.FC<IAnimatedComponentProps> = props => {

    const ref = React.useRef<HTMLDivElement | null>(null);
    const entry = useIntersectionObserver(ref, {
        freezeOnceVisible: true,
        threshold: props.threshold ? props.threshold : 0.4
    });
    const isVisible = !!entry?.isIntersecting;

    return <div className={'animated-component' + (isVisible ? ' active' : '')} ref={ref}>
        {props.children}
    </div>;

};