import React from 'react';
import {
    IFooterLinkProps,
    IFooterSocialProps
} from '@/models/footerlinks';
import TranslationService, {
    ITranslationService
} from '@/services/TranslationService';
import './styles.scss';
import { TrackingCategories } from '@/models/trackingModel';
import LanguageSelector from '../LanguageSelector';
import {
    Icon,
    ICON_TYPES
} from '@/components/Icons';
import { Social } from '@/components/Social';
import { FooterLinks } from '@/components/FooterLinks';
import { getYear } from '@/utils';

export interface Props {
    footerIcon: ICON_TYPES;
    footerLinks: IFooterLinkProps[];
    footerSocial: IFooterSocialProps[];
}

export class Footer extends React.Component<Props> {
    private readonly translationService: ITranslationService;

    constructor(props: any) {
        super(props);
        this.translationService = TranslationService.getInstance();
    }

    private translate = (key: string) => this.translationService.translate(key);

    private getSwipejobsSection() {

        return (
            <React.Fragment>
                <div className='footer__logo'>
                    <Icon icon={this.props.footerIcon}/>
                </div>

                <div className='footer__language-selector'>
                    <LanguageSelector trackingCategory={TrackingCategories.Footer}/>
                </div>
                <Social items={this.props.footerSocial}/>
                <div className='footer__copyright'>
                    {this.translate('swipejobs')} &copy; {getYear()}
                </div>
            </React.Fragment>
        );
    }

    render() {
        return (
            <footer className='footer component-wrapper'>
                <div className='footer__inner component-wrapper__inner'>
                    <div className='footer__left'>
                        {this.props.footerLinks.map((item, index) => {
                            return (
                                <div className='footer__col' key={index}>
                                    <FooterLinks {...item} />
                                </div>
                            );
                        })}
                    </div>
                    <div className='footer__right'>{this.getSwipejobsSection()}</div>
                </div>
            </footer>
        );
    }
}

export default Footer;
