import * as React from 'react';
import {
    Popover,
    PopoverInteractionKind,
    PopoverPosition,
    Spinner
} from '@blueprintjs/core';
import { forEach } from 'lodash';
import TranslationService, { ITranslationService } from '@/services/TranslationService';
import {
    TrackingCategoryType,
    TrackingNames,
    TrackingNameType
} from '@/models/trackingModel';
import TrackingService, { ITrackingService } from '@/services/TrackingService';
import {
    Icon,
    ICON_TYPES
} from '../Icons';
import './styles.scss';

interface ILoaders {
    [lang: string]: boolean;
}

export interface Props {
    trackingCategory: TrackingCategoryType;
}

export interface State {
    isOpen: boolean;
    language: string;
    languages: string[];
    loaders: ILoaders;
}

class LanguageSelector extends React.Component<Props, State> {
    private readonly translationService: ITranslationService;
    private readonly trackingService: ITrackingService;

    constructor(props: any) {
        super(props);
        this.translationService = TranslationService.getInstance();
        this.trackingService = new TrackingService();
        const language: string = this.translationService.getLanguage();
        const languages: string[] = this.translationService.getLanguages();
        const loaders: ILoaders = {};
        forEach(languages, (item: string) => {
            loaders[item] = false;
        });

        this.state = {
            isOpen: false,
            language,
            languages,
            loaders
        };
    }

    private translate = (key: string) => this.translationService.translate(key);
    private trackClick = (name: TrackingNameType) => this.trackingService.trackClick(this.props.trackingCategory, name);

    private async changeLanguageAsync(language: string) {
        this.trackClick(language === 'en' ? TrackingNames.LanguageEnClick : TrackingNames.LanguageEsClick);
        const loaders = this.state.loaders;
        loaders[language] = true;
        await this.setState({ loaders });
        await this.translationService.changeLanguageAsync(language);
        loaders[language] = false;

        this.setState({
            isOpen: false,
            language,
            loaders
        });
    }

    private getContent() {
        return (
            <div className='select-lang-wrapper'>
                <div className='select-lang-title'>
                    {this.translate('selectLanguage')}
                </div>
                {
                    this.state.languages.map(item => (
                        <div className='select-lang-item'
                             onClick={() => this.changeLanguageAsync(item)}
                             key={item}>
                            <div className='select-lang-item-content'>
                                {this.translate(`language-${item}`)}
                            </div>
                            <div className='select-lang-item-loader'>
                                {
                                    this.state.loaders[item] && <Spinner size={20}/>
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }

    private handleInteraction(nextOpenState: boolean) {
        nextOpenState && !this.state.isOpen && this.trackClick(TrackingNames.LanguageSelectorOpen);
        this.setState({ isOpen: nextOpenState });
    }

    public render() {
        return (
            <div className='language-selector'>
                <Icon icon={ICON_TYPES.GLOBE}/>
                <Popover
                    className='popover'
                    position={PopoverPosition.TOP_LEFT}
                    interactionKind={PopoverInteractionKind.CLICK}
                    captureDismiss={true}
                    content={this.getContent()}
                    enforceFocus={false}
                    onInteraction={(state: any) => this.handleInteraction(state)}
                    isOpen={this.state.isOpen}
                >
                    <div className='language-display'>
                        <div className='language-selected'>
                            {this.translate(`language-${this.state.language}`)}
                        </div>
                        <div className={'language-selector__arrow' + (this.state.isOpen ? ' active' : '')}>
                            <Icon icon={ICON_TYPES.CHEVRON}/>
                        </div>
                    </div>
                </Popover>
            </div>
        );
    }
}

export default LanguageSelector;