import * as React from 'react';
import './styles.scss';
import reactStringReplace from 'react-string-replace';
import { ContentCol } from '@/components/ContentCol';
import { ImageContentCol } from '@/components/ImageContentCol';
import { PartnerBanner } from '@/components/PartnerBanner';
import * as HOME from '@/data/pages/partner-premier';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import { TrackingCategories } from '@/models/trackingModel';
import RenderParagraphs from '@/components/RenderParagraphs';

const full_partner_name = 'EZ Payroll & Staffing Solutions';
const short_partner_name = 'EZ Payroll';

const EZPayroll = () => {
    const { translate } = useTranslationHook();

    return <div id='top' className='home-page'>
        <PartnerBanner
            {...HOME.PARTNER_BANNER}
            img={{
                src: '/images/ezpayroll.png',
                alt: 'ezpayroll'
            }}
        />
        <ImageContentCol
            leftContent={<ContentCol
                title={[
                    { text: translate('homeLeftTitle') }
                ]}
                listContent={[
                    translate('homeDotPoint1'),
                    translate('homeDotPoint2'),
                    translate('homeDotPoint3')
                ]}
                trackingCategory={TrackingCategories.EZPayroll}
            />}
            rightContent={<ContentCol
                title={[
                    { text: translate('homeRightTitle') }
                ]}
                listContent={[
                    translate('homeDotPoint4'),
                    translate('homeDotPoint5'),
                    translate('homeDotPoint6')
                ]}
                trackingCategory={TrackingCategories.EZPayroll}
            />}
        />
        <p className='home-paragraph'>{reactStringReplace(translate('homeParagraphVariant'), '{0}', (match, i) => (
            <span>{full_partner_name}</span>
        ))}</p>
        <p className='home-paragraph'>{translate('homeParagraphVariantDescription')}</p>
        <h3 className='home-paragraph'>{translate('hiringTitle')}</h3>
        <p className='home-paragraph'>{reactStringReplace(translate('homeParagraphVariant'), '{0}', (match, i) => (
            <span>{short_partner_name}</span>
        ))}</p>
        <p className='home-paragraph'>{reactStringReplace(translate('homeParagraphVariantDescription'), '{0}', (match, i) => (
            <span>{short_partner_name}</span>
        ))}</p>
        <RenderParagraphs translateLabel='hiringParagraphs'/>

    </div>;
};
export default EZPayroll;