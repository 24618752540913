import * as React from 'react';
import {
    Route,
    Routes,
    Navigate
} from 'react-router-dom';
import { ScrollToTopWrapper } from '../ScrollToTopWrapper';

import About from '@/pages/About';
import PrivacyPolicy from '@/pages/PrivacyPolicy';
import NotFound from '@/pages/NotFound';
import './styles.scss';

export interface Props {
    pages: {
        routeName: string,
        Component: () => JSX.Element,
    }[];
}

export class ActivePage extends React.Component<Props> {

    render() {

        return (
            <div className='active-page'>
                <ScrollToTopWrapper>
                    <Routes>
                        {this.props.pages.map(({
                                                   routeName,
                                                   Component
                                               }, index) => (
                            // @ts-ignore
                            <Route path={routeName}
                                   key={index}
                                   element={<Component/>}>
                            </Route>
                        ))}
                        <Route path='/about' element={<About/>}/>
                        <Route path='/privacy' element={<PrivacyPolicy/>}/>
                        <Route path='/solutions/it' element={<Navigate replace to='/our-technology' />} />
                        <Route path='*' element={<NotFound/>}/>
                    </Routes>
                </ScrollToTopWrapper>
            </div>
        );
    }
}

export default ActivePage;
