import React from 'react';
import TranslationService, {
    ITranslationService
} from '@/services/TranslationService';
import Button from '@/components/Button';
import InputMask from 'react-input-mask';
import HttpService, { IHttpService } from '@/services/HttpService';
import ModalView from '@/components/ModalView';
import Loader from '@/components/Loader';
import {
    TrackingCategoryType,
    TrackingNames,
    TrackingNameType
} from '@/models/trackingModel';
import TrackingService, {
    ITrackingService
} from '@/services/TrackingService';
import { externalLinks } from '@/models/external-links';
import InputWithValidation from '@/components/InputWithValidation';
import { AnimatedComponent } from '@/components/AnimatedComponent';
import { isEmailValid } from '@/utils';
import { isPhoneNumberValid } from '@/utils';
import './styles.scss';

export interface Props {
    isMessageRequired: boolean;
    trackingCategory: TrackingCategoryType;
}

export interface State {
    submitClicked: boolean;
    name: string;
    email: string;
    companyName: string;
    phoneNumber: string;
    message: string;
    loaderIsOpen: boolean;
    modalTitle: string;
    modalSubtitle: string;
    modalIsOpen: boolean;
    modalIsError: boolean;
}

export class ContactUsView extends React.Component<Props, State> {
    private readonly translationService: ITranslationService;
    private readonly httpService: IHttpService;
    private readonly trackingService: ITrackingService;

    constructor(props: any) {
        super(props);
        this.translationService = TranslationService.getInstance();
        this.httpService = new HttpService();
        this.trackingService = new TrackingService();

        this.state = {
            submitClicked: false,
            name: '',
            email: '',
            companyName: '',
            phoneNumber: '',
            message: '',
            loaderIsOpen: false,
            modalTitle: '',
            modalSubtitle: '',
            modalIsOpen: false,
            modalIsError: false
        };
    }

    private translate = (key: string) => this.translationService.translate(key);
    private trackClick = (name: TrackingNameType) =>
        this.trackingService.trackClick(this.props.trackingCategory, name);

    private isValid() {
        const {
            name,
            email,
            companyName,
            phoneNumber,
            message
        } = this.state;
        const { isMessageRequired } = this.props;

        const areMandatoryFieldValid =
            !!name &&
            isEmailValid(email) &&
            !!companyName &&
            isPhoneNumberValid(phoneNumber);
        const isMessageFieldValid =
            !isMessageRequired || (isMessageRequired && !!message);
        return areMandatoryFieldValid && isMessageFieldValid;
    }

    private getForm() {
        const {
            submitClicked,
            name,
            email,
            companyName,
            phoneNumber,
            message
        } =
            this.state;

        return (
            <div className='form'>
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <InputWithValidation
                            value={name}
                            placeholder={this.translate('name')}
                            onChange={(value: string) => this.setState({ name: value })}
                            checkValidation={(value: string) =>
                                !submitClicked || (submitClicked && !!value)
                            }
                            submitClicked={false}
                        />
                    </div>
                    <div className='col-12 col-md-6'>
                        <InputWithValidation
                            value={email}
                            placeholder={this.translate('email')}
                            onChange={(value: string) => this.setState({ email: value })}
                            checkValidation={(value: string) =>
                                !submitClicked || (submitClicked && isEmailValid(value))
                            }
                            submitClicked={false}
                        />
                    </div>
                    <div className='col-12 col-md-6'>
                        <InputWithValidation
                            value={companyName}
                            placeholder={this.translate('companyName')}
                            onChange={(value: string) =>
                                this.setState({ companyName: value })
                            }
                            checkValidation={(value: string) =>
                                !submitClicked || (submitClicked && !!value)
                            }
                            submitClicked={false}
                        />
                    </div>
                    <div className='col-12 col-md-6'>
                        <div className='input-with-validation'>
                            <label htmlFor='form-input-phone'>
                                {this.translate('phoneNumber')}
                            </label>
                            <InputMask
                                id='form-input-phone'
                                mask='(999) 999 9999'
                                value={phoneNumber}
                                onChange={(value: any) =>
                                    this.setState({ phoneNumber: value?.target?.value })
                                }
                            >
                                {() => (
                                    <input
                                        className='form-input'
                                        type='text'
                                        placeholder={this.translate('phoneNumber')}
                                    />
                                )}
                            </InputMask>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='input-with-validation'>
                            <label htmlFor='form-input-message'>
                                {this.translate('message').replace('...', '')}
                            </label>
                            <textarea
                                id='form-input-message'
                                className='form-input textarea'
                                value={message}
                                onChange={(value) =>
                                    this.setState({ message: value?.target?.value })
                                }
                                placeholder={this.translate('message')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private showModal(isSuccess: boolean) {
        this.setState({
            modalTitle: this.translate(
                isSuccess ? 'successModalTitle' : 'errorModalTitle'
            ),
            modalSubtitle: this.translate(
                isSuccess ? 'successModalBody' : 'errorModalBody'
            ),
            modalIsOpen: true,
            modalIsError: !isSuccess
        });
    }

    private closeModal() {
        this.setState({ modalIsOpen: false });
    }

    private toggleLoader(loaderIsOpen: boolean) {
        this.setState({ loaderIsOpen });
    }

    private async submit() {
        if (!this.state.submitClicked) {
            this.setState({ submitClicked: true });
        }

        if (!this.isValid()) {
            return;
        }

        try {
            const {
                name,
                email,
                companyName,
                phoneNumber,
                message
            } = this.state;

            const body = {
                name,
                email,
                companyName,
                phoneNumber,
                message
            };

            this.toggleLoader(true);
            await this.httpService.post(externalLinks.contactFormUrl, body);
            this.trackClick(TrackingNames.ContactUsSubmit);
            this.showModal(true);
            this.setState({
                submitClicked: false,
                name: '',
                email: '',
                companyName: '',
                phoneNumber: '',
                message: ''
            });
        } catch (e) {
            this.trackClick(TrackingNames.ContactUsSubmitError);
            this.showModal(false);
        } finally {
            this.toggleLoader(false);
        }
    }

    render() {
        const {
            loaderIsOpen,
            modalTitle,
            modalSubtitle,
            modalIsOpen,
            modalIsError
        } = this.state;

        return (
            <AnimatedComponent threshold={0.5}>
                <div className='contact-us-view'>
                    <div className='info-tiles-view-wrapper'>
                        <h6 className='title'>{this.translate('contactUs')}</h6>
                        {this.getForm()}
                        <Button
                            title={this.translate('submitNow')}
                            onPress={() => this.submit()}
                            whiteBorder={true}
                        />
                    </div>
                    <Loader title={this.translate('submitting')} isOpen={loaderIsOpen}/>
                    <ModalView
                        title={modalTitle}
                        subtitle={modalSubtitle}
                        isOpen={modalIsOpen}
                        closeModal={() => this.closeModal()}
                        isError={modalIsError}
                    />
                </div>
            </AnimatedComponent>
        );
    }
}

export default ContactUsView;
