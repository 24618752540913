import React from 'react';
import TranslationService, {
    ITranslationService
} from '@/services/TranslationService';
import { Tile } from '@/components/Tile';
import { AnimatedComponent } from '@/components/AnimatedComponent';
import { Link } from 'react-router-dom';
import './styles.scss';

export interface IInfoTiles {
    title: string;
    subtitle: string;
}

export interface Props {
    title?: string;
    subtitle?: string;
    tileList: string[];
    footer?: string;
    image?: string[];
    navigate?: string[];
    clickable?: boolean;
}

export class InfoTilesView extends React.Component<Props> {
    private readonly translationService: ITranslationService;

    constructor(props: any) {
        super(props);
        this.translationService = TranslationService.getInstance();
    }

    private translate = (key: string) => this.translationService.translate(key);

    render() {
        const {
            title,
            subtitle,
            footer,
            tileList,
            clickable,
            navigate
        } = this.props;

        const renderTiles = (tile:IInfoTiles ,index: number) => {
            if(!navigate){
                return (
                    <Tile clickable={clickable || false}
                          title={tile.title}
                          content={tile.subtitle} />
                )
            } else {
                return (
                    <Link className='text-link' to={navigate[index]}>
                        <Tile clickable={clickable || false}
                              title={tile.title}
                              content={tile.subtitle} />
                    </Link>
                )
            }

        }

        const tiles: IInfoTiles[] = tileList.map(item => ({
            title: this.translate(`${item}Title`),
            subtitle: this.translate(`${item}Content`)
        }));

        return (<AnimatedComponent threshold={0.6}>
            <div className='info-tiles-view'>
                <div className='info-tiles-view-wrapper'>
                    <h1 className='title main-title'>
                        {title}
                    </h1>
                    {subtitle ? <p className='subtitle secondary-title'>{subtitle}</p> : null}
                    <div className='info-tiles-view__grid'>
                        {tiles.map((tile: IInfoTiles, index: number) => (
                            <div className='info-tiles-view__item' key={index}>
                                {renderTiles(tile,index)}
                            </div>
                        ))}
                    </div>
                    {footer ? <p className='info-tiles-view__footer'>{footer}</p> : null}
                </div>
            </div>
        </AnimatedComponent>);
    }
}

export default InfoTilesView;
