import { externalLinks } from './external-links';
import { ICON_TYPES } from '@/components/Icons';
import {TrackingNames, TrackingCategories} from '@/models/trackingModel';

export const FOOTER_SOCIAL_LINKS = [
  {
    title: 'Find Swipejobs on Facebook',
    link: externalLinks.sjFacebook,
    icon: ICON_TYPES.FACEBOOK,
    trackingName: TrackingNames.FooterFacebookClick,
    trackingCategory: TrackingCategories.Footer
  },
  {
    title: 'Find Swipejobs on Instagram',
    link: externalLinks.sjInstagram,
    icon: ICON_TYPES.INSTAGRAM,
    trackingName: TrackingNames.FooterInstagramClick,
    trackingCategory: TrackingCategories.Footer
  },
  {
    title: 'Find Swipejobs on Linked In',
    link: externalLinks.psLinkedin,
    icon:ICON_TYPES.LINKEDIN,
    trackingName: TrackingNames.FooterLinkedinClick,
    trackingCategory: TrackingCategories.Footer
  }
];
