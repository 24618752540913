import * as React from 'react';
import {
    Icon,
    ICON_TYPES
} from '../Icons';
import {
    TrackingCategoryType,
    TrackingNameType
} from '@/models/trackingModel';
import { useTrackingHook } from '@/hooks/useTrackingHook';
import './styles.scss';

interface ISocialProps {
    items: {
        title: string;
        link: string;
        icon: ICON_TYPES;
        trackingName: TrackingNameType;
        trackingCategory: TrackingCategoryType;
    }[];
}

export const Social: React.FC<ISocialProps> = ({ items }) => {
    const { trackClick } = useTrackingHook();

    return (
        <ul className='social'>
            {items.map((item) => {
                return (
                    <li key={item.link}>
                        <a
                            href={item.link}
                            key={item.link}
                            rel='noopener noreferrer'
                            target='_blank'
                            onClick={() =>
                                trackClick(item.trackingCategory, item.trackingName)
                            }
                        >
                            <Icon icon={ICON_TYPES[item.icon]}/>
                            <span className='sr-only'>{item.title}</span>
                        </a>
                    </li>
                );
            })}
        </ul>
    );
};
