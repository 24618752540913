import { map } from 'lodash';

export interface IPreloadService {
    preloadImages(): void;
}

const imagesUrls = [
    'images/business-animate-image.png',
    'images/staffing-animate-image.png',
    'images/about-animate-image.png',
    'images/home-animate-image.png',
    'images/worker-animate-image.png',
    'images/get-notified.png',
    'images/fully-digital-on-boarding.png',
    'images/build-your-order.png',
    'images/mobile-animate-image.png',
    'images/profile-up-to-date.png',
    'images/we-match.png',
    'images/laptop-animate-image.png',
    'images/bg-info-tiles.jpg',
    'images/rate-the-job.png',
    'images/submit-ratings-and-hours.png',
    'images/invite-workers-back.png',
    'images/add-necessary-requirements.png',
    'images/add-shifts-workers.png',
    'images/instant-quote.png',
    'images/virtual-staffing-company-laptop.png'
];

export default class PreloadService implements IPreloadService {
    preloadImages(): void {
        const images: any = [];
        map(imagesUrls, (image: string, index: number) => {
            images[index] = new Image();
            images[index].src = image;
        });
    }
}
