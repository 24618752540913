import { externalLinks } from '@/models/external-links';
import {
    isMobile,
    isAndroid,
    isIOS
} from '@/utils';

export const getJoinTodayLink = () => {
    const isMobilePlatform = isMobile();
    return (isMobilePlatform && isAndroid()) ? externalLinks.customerAppAndroid :
        (isMobilePlatform && isIOS()) ? externalLinks.customerAppIos :
            externalLinks.customerDesktop;
};