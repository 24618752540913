import { IHeroBannerProps } from '@/components/HeroBanner';
import {
    TrackingCategories,
    TrackingNames
} from '@/models/trackingModel';

export const PARTNER_BANNER: IHeroBannerProps = {
    content: {
        isHero: true,
        title: [
            {text: 'aVirtual'},
            {hasEmphasis: true, text: 'staffing'},
            {text: 'company'}
        ],
        trackingCategory: TrackingCategories.Home,
        subtitle: 'deliversWorkforceDigitally',
        links: [
            {url: 'https://www.swipejobs.com/workers#top', text: 'iAmAWorker', trackingName: TrackingNames.ImAWorkerClick},
            {url: 'https://www.swipejobs.com/business#top', text: 'iAmABusiness', trackingName: TrackingNames.ImABusinessClick}
        ]
    },
    img: {
        src: '',
        alt: ''
    }
}

export const INDUSTRIES = [
    'warehousing',
    'manufacturing',
    'foodProduction',
    'logistics',
    'generalLabor',
    'customerService',
    'clerical'
];

export const FEATURE_CTA = {
    title: 'wantToLearnMoreTitle',
    subtitle: 'wantToLearnMoreSubtitle',
    trackingCategory: TrackingCategories.Home,
    links: [
        {
            text: 'iAmAWorker',
            trackingName: TrackingNames.LearnMoreImAWorkerClick,
            location: '/workers#top'
        },
        {
            text: 'iAmABusiness',
            trackingName: TrackingNames.LearnMoreImABusinessClick,
            location: '/business#top'
        }
    ]
};