import axios from 'axios';
import i18n from 'i18next';
import { map } from 'lodash';
import { initReactI18next } from 'react-i18next';

export interface ITranslationService {
    init(): Promise<boolean>;
    getLanguages(): string[];
    getLanguage(): string;
    changeLanguageAsync(language: string): Promise<any>;
    translate(key: string): string;
    translateObject(key: string): object;
}

export default class TranslationService implements ITranslationService {
    static serviceInstance: ITranslationService;
    private readonly i18nInstance: i18n.i18n;
    private languages: string[] = [];

    static getInstance() {
        if (this.serviceInstance === undefined) {
            this.serviceInstance = new TranslationService();
        }
        return this.serviceInstance;
    }

    constructor() {
        this.i18nInstance = i18n;
    }

    async init(): Promise<boolean> {
        try {
            const response = await axios.get('/translations.json', {});
            const resources: { [lang: string]: any } = response.data;
            this.languages = map(resources, (value, key) => key);
            const preferredLocale = 'en';

            this.i18nInstance
                .use(initReactI18next)
                .init({
                    resources,
                    lng: preferredLocale,
                    keySeparator: false,
                    interpolation: {
                        escapeValue: false
                    }
                });
            return true;
        } catch (error) {
            return false;
        }
    }

    getLanguage(): string {
        return this.i18nInstance.language;
    }

    getLanguages(): string[] {
        return this.languages;
    }

    async changeLanguageAsync(language: string): Promise<void> {
        return new Promise((resolve, reject) => this.i18nInstance.changeLanguage(
            language,
            (error) => {
                if (error) {
                    reject(error);
                    return;
                }
                resolve();
            }
        ));
    }

    translate(key: string): string {
        return this.i18nInstance.t(key);
    }

    translateObject(key: string): Array<any> {
        return this.i18nInstance.t(key, { returnObjects: true });
    }
}
