import * as React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { ReactNode } from 'react';
import './styles.scss';

interface Props {
    to: string;
    className?: string;
    excludeBaseClassName?: boolean;
    onClick?: Function;
    children: ReactNode;
}

const Link: React.FC<Props> = ({
                                   to,
                                   className,
                                   excludeBaseClassName,
                                   onClick,
                                   children
                               }) => {

    const customClassName = className ? `${className}` : '';

    return <NavHashLink to={to}
                        className={excludeBaseClassName ? `${customClassName}` : `nav-link ${customClassName}`}
                        scroll={el => {
                            window.scroll({
                                top: el.offsetTop - 145,
                                left: 0,
                                behavior: 'auto'
                            });
                        }}
                        onClick={() => onClick && onClick()}>
        {children}
    </NavHashLink>;

};

export default Link;