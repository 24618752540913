import {
    TrackingCategoryType,
    TrackingActionTypes,
    TrackingNameType
} from '@/models/trackingModel';

export const useTrackingHook = () => {

    const trackClick = (category: TrackingCategoryType, name: TrackingNameType, value?: number): void => {
        (window as any)?._paq?.push(['trackEvent', category, TrackingActionTypes.ClickEvent, name, value]);
    };

    const trackPage = (category: TrackingCategoryType): void => {
        (window as any)?._paq?.push(['trackEvent', category, TrackingActionTypes.PageEvent]);
    };

    return {
        trackPage,
        trackClick
    };

};