import * as React from 'react';
import './styles.scss';
import Link from '../Link';
import {
    TrackingCategoryType,
    TrackingNameType
} from '@/models/trackingModel';
import { useTrackingHook } from '@/hooks/useTrackingHook';
import { useTranslationHook } from '@/hooks/useTranslationHook';
import {
    Icon,
    ICON_TYPES
} from '../Icons';

export interface IContentColProps {
  isHero?: boolean;
  includeLogo?: boolean;
  navigate?: string[];
  title?: {
    text: string
    hasEmphasis?: boolean;
  }[];
  subtitle?: string;
  listContent?: string[];
  trackingCategory: TrackingCategoryType
  links?: {
    url: string;
    text: string;
    trackingName: TrackingNameType
  }[]
}

export const ContentCol: React.FC<IContentColProps> = ({ navigate, isHero, includeLogo, title, subtitle, listContent, trackingCategory, links }) => {

  const { trackClick } = useTrackingHook();
  const { translate } = useTranslationHook();

  const outputTitleText = () => {
    if (!title) {
        return <React.Fragment></React.Fragment>
    } else {
        return title.map((item, index) => {
                return item.hasEmphasis ? (
                    <span key={index}>
                        {translate(item.text)}
                    </span>
                    ) : translate(item.text);
        });
    }
  }

  const outputListContent = () => {

    if(!listContent) {
        return <React.Fragment></React.Fragment>
    } else {
         return (
            <ul>
                {listContent.map((item, index) => {
                    if (navigate) {
                        return (
                            <li key={index}>
                                <a href={navigate[index]}>
                                    {translate(item)}
                                </a>
                            </li>
                        );
                    } else {
                       return (
                            <li key={index}>
                                {translate(item)}
                            </li>
                       );
                    }
                })}
            </ul>
         );
    }
  }

  return <div className={'content-col' + (isHero ? ' content-col--hero' : '')}>
    <div className='content-col__body'>
      <h3 className='content-col__title main-title'>
        {includeLogo ? <div className='content-col__logo'>
          <Icon icon={ICON_TYPES.LOGO_EXCHANGE} />
        </div> : null}
        {outputTitleText()}
      </h3>
      {subtitle ? <p className={'content-col__subtitle secondary-title'}>{translate(subtitle)}</p> : null}
      {listContent ? outputListContent() : null}
    </div>
    {links ? <div className='content-col__cta'>
      {links.map((item, index) => {
        return <div key={index} className='content-col__cta-item'>
          {
            item.url.includes('http') &&
            <a href={item.url}
               key={index}
               onClick={() => trackClick(trackingCategory, item.trackingName)}>
               <div className='button'>{translate(item.text)}</div>
            </a>
          }
          {
            !item.url.includes('http') &&
            <Link key={index}
                  to={item.url}
                  excludeBaseClassName={true}
                  onClick={() => trackClick(trackingCategory, item.trackingName)}>
                <div className='button'>{translate(item.text)}</div>
            </Link>
          }
        </div>
      })}</div> : null}
  </div>

}
