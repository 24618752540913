import * as React from 'react';
import { AnimatedComponent } from '../AnimatedComponent';
import './styles.scss';

export enum IMAGE_CONTENT_COL_SPACING {
    NONE = 'image-content-col--no-margin',
    TOP = 'image-content-col--margin-top',
    BOTTOM = 'image-content-col--margin-bottom'
}

interface IImageContentColProps {
    title?: string;
    reverse?: boolean;
    spacing?: IMAGE_CONTENT_COL_SPACING;
    leftContent: React.ReactNode;
    rightContent: React.ReactNode;
}

export const ImageContentCol: React.FC<IImageContentColProps> = ({
                                                                     title,
                                                                     leftContent,
                                                                     rightContent,
                                                                     reverse,
                                                                     spacing
                                                                 }) => {

    return <AnimatedComponent>
        <>
            {title ? <div className={'component-wrapper'}><h1 className='title main-title'>{title}</h1></div> : null}
            <div className={'image-content-col' + (reverse ? ' image-content-col--reverse' : '') + (spacing ? ` ${spacing}` : '')}>
                <div className='image-content-col__inner'>
                    <div className='image-content-col__left'>{leftContent}</div>
                    <div className='image-content-col__right'>{rightContent}</div>
                </div>
            </div>
        </>
    </AnimatedComponent>;

};