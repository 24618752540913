export type TrackingCategoryType = 'navigation' |
    'side_navigation' |
    'home' |
    'business' |
    'worker' |
    'about' |
    'our-locations' |
    'location' |
    'app_link' |
    'privacy_policy' |
    'staffing' |
    'footer' |
    'premier' |
    '1st_class_staffing' |
    'brand_pack' |
    'ez_payroll' |
    'our_technology' |
    'philosophy' |
    'gsa-schedule' |
    'not_found' |
    'find-workers-contact';

export class TrackingCategories {
    static readonly Navigation: TrackingCategoryType = 'navigation';
    static readonly SideNavigation: TrackingCategoryType = 'side_navigation';
    static readonly Home: TrackingCategoryType = 'home';
    static readonly Business: TrackingCategoryType = 'business';
    static readonly Worker: TrackingCategoryType = 'worker';
    static readonly FindWorkersContact: TrackingCategoryType = 'find-workers-contact';
    static readonly About: TrackingCategoryType = 'about';
    static readonly OurLocations: TrackingCategoryType = 'our-locations';
    static readonly AppLink: TrackingCategoryType = 'app_link';
    static readonly Staffing: TrackingCategoryType = 'staffing';
    static readonly PrivacyPolicy: TrackingCategoryType = 'privacy_policy';
    static readonly Footer: TrackingCategoryType = 'footer';
    static readonly Premier: TrackingCategoryType = 'premier';
    static readonly FirstClassStaffing: TrackingCategoryType = '1st_class_staffing';
    static readonly BrandPack: TrackingCategoryType = 'brand_pack';
    static readonly EZPayroll: TrackingCategoryType = 'ez_payroll';
    static readonly OurTechnology: TrackingCategoryType = 'our_technology';
    static readonly Philosophy: TrackingCategoryType = 'philosophy';
    static readonly NotFound: TrackingCategoryType = 'not_found';
}

export type TrackingActionType = 'click_event' | 'page_event';

export class TrackingActionTypes {
    static readonly ClickEvent: TrackingActionType = 'click_event';
    static readonly PageEvent: TrackingActionType = 'page_event';
}

export type TrackingNameType = 'nav_logo_click' |
    'nav_home_click' |
    'nav_business_click' |
    'nav_worker_click' |
    'nav_staffing_click' |
    'nav_about_click' |
    'nav_philosophy_click' |
    'nav_blog_click' |
    'nav_contact_click' |
    'nav_technology_click' |
    'nav_jobs_click' |
    'nav_business_sign_in_click' |
    'side_nav_open_click' |
    'side_nav_overlay_close_click' |
    'side_nav_button_close_click' |
    'language_selector_open' |
    'language_en_click' |
    'language_es_click' |
    'im_a_worker_click' |
    'im_a_business_click' |
    'find_workers_click' |
    'find_me_work_click' |
    'learn_more_im_a_worker_click' |
    'learn_more_im_a_business_click' |
    'find_workers_now_click' |
    'get_more_information_click' |
    'business_google_play_store_click' |
    'business_app_store_click' |
    'worker_google_play_store_click' |
    'worker_app_store_click' |
    'slide_show_forward' |
    'slide_show_back' |
    'faq_open' |
    'faq_close' |
    'contact_us_submit' |
    'contact_us_submit_error' |
    'find_work_click' |
    'get_started_mobile_click' |
    'get_started_desktop_click' |
    'send_sms_click' |
    'send_sms_success' |
    'send_sms_error' |
    'footer_facebook_click' |
    'footer_instagram_click' |
    'footer_linkedin_click' |
    'footer_worker_guidelines_click' |
    'footer_worker_faq_click' |
    'footer_business_join_click' |
    'footer_business_faq_click' |
    'footer_about_contact_us_click' |
    'footer_about_careers_click' |
    'footer_about_privacy_policy_click' |
    'nav_our-locations_click' |
    'nav_location_click' |
    'footer_gsaschedule_click' |
    'footer_worker_hr_login_click' |
    'footer_hr_login_click' |
    'footer_about_corporate_social' |
    'footer_benefits_click' |
    'footer_about_clients' |
    'web_center_click' |
    'footer_about_employment' |
    'transparency_coverage';

export class TrackingNames {
    static readonly NavLogoClick: TrackingNameType = 'nav_logo_click';
    static readonly NavHomeClick: TrackingNameType = 'nav_home_click';
    static readonly NavBusinessClick: TrackingNameType = 'nav_business_click';
    static readonly NavOurLocationsClick: TrackingNameType = 'nav_our-locations_click';
    static readonly NavLocationClick: TrackingNameType = 'nav_location_click';
    static readonly NavWorkerClick: TrackingNameType = 'nav_worker_click';
    static readonly NavStaffingClick: TrackingNameType = 'nav_staffing_click';
    static readonly NavAboutClick: TrackingNameType = 'nav_about_click';
    static readonly NavBusinessSignInClick: TrackingNameType = 'nav_business_sign_in_click';
    static readonly SideNavOpenClick: TrackingNameType = 'side_nav_open_click';
    static readonly SideNavOverlayCloseClick: TrackingNameType = 'side_nav_overlay_close_click';
    static readonly SideNavButtonCloseClick: TrackingNameType = 'side_nav_button_close_click';
    static readonly LanguageSelectorOpen: TrackingNameType = 'language_selector_open';
    static readonly LanguageEnClick: TrackingNameType = 'language_en_click';
    static readonly LanguageEsClick: TrackingNameType = 'language_es_click';
    static readonly ImAWorkerClick: TrackingNameType = 'im_a_worker_click';
    static readonly ImABusinessClick: TrackingNameType = 'im_a_business_click';
    static readonly FindWorkersClick: TrackingNameType = 'find_workers_click';
    static readonly FindMeWorkClick: TrackingNameType = 'find_me_work_click';
    static readonly LearnMoreImAWorkerClick: TrackingNameType = 'learn_more_im_a_worker_click';
    static readonly LearnMoreImABusinessClick: TrackingNameType = 'learn_more_im_a_business_click';
    static readonly FindWorkersNowClick: TrackingNameType = 'find_workers_now_click';
    static readonly GetMoreInformation: TrackingNameType = 'get_more_information_click';
    static readonly BusinessGooglePlayStoreClick: TrackingNameType = 'business_google_play_store_click';
    static readonly BusinessAppStoreClick: TrackingNameType = 'business_app_store_click';
    static readonly WorkerGooglePlayStoreClick: TrackingNameType = 'worker_google_play_store_click';
    static readonly WorkerAppStoreClick: TrackingNameType = 'worker_app_store_click';
    static readonly SlideShowForward: TrackingNameType = 'slide_show_forward';
    static readonly SlideShowBack: TrackingNameType = 'slide_show_back';
    static readonly FaqOpen: TrackingNameType = 'faq_open';
    static readonly FaqClose: TrackingNameType = 'faq_close';
    static readonly ContactUsSubmit: TrackingNameType = 'contact_us_submit';
    static readonly ContactUsSubmitError: TrackingNameType = 'contact_us_submit_error';
    static readonly FindWorkClick: TrackingNameType = 'find_work_click';
    static readonly GetStartedMobileClick: TrackingNameType = 'get_started_mobile_click';
    static readonly GetStartedDesktopClick: TrackingNameType = 'get_started_desktop_click';
    static readonly SendSmsClick: TrackingNameType = 'send_sms_click';
    static readonly SendSmsSuccess: TrackingNameType = 'send_sms_success';
    static readonly SendSmsError: TrackingNameType = 'send_sms_error';
    static readonly FooterFacebookClick: TrackingNameType = 'footer_facebook_click';
    static readonly FooterInstagramClick: TrackingNameType = 'footer_instagram_click';
    static readonly FooterLinkedinClick: TrackingNameType = 'footer_linkedin_click';
    static readonly FooterWorkerGuidelinesClick: TrackingNameType = 'footer_worker_guidelines_click';
    static readonly FooterWorkerFaqClick: TrackingNameType = 'footer_worker_faq_click';
    static readonly FooterWorkerHRLoginClick: TrackingNameType = 'footer_worker_hr_login_click';
    static readonly FooterHRLoginClick: TrackingNameType = 'footer_hr_login_click';
    static readonly FooterBenefitsClick: TrackingNameType = 'footer_benefits_click';
    static readonly FooterBusinessJoinClick: TrackingNameType = 'footer_business_join_click';
    static readonly FooterBusinessFaqClick: TrackingNameType = 'footer_business_faq_click';
    static readonly FooterAboutContactUsClick: TrackingNameType = 'footer_about_contact_us_click';
    static readonly FooterAboutCareersClick: TrackingNameType = 'footer_about_careers_click';
    static readonly FooterAboutPrivacyPolicyClick: TrackingNameType = 'footer_about_privacy_policy_click';
    static readonly FooterAboutCorporateSocial: TrackingNameType = 'footer_about_corporate_social';
    static readonly FooterAboutEmployment: TrackingNameType = 'footer_about_employment';
    static readonly FooterClients: TrackingNameType = 'footer_about_clients';
    static readonly TransparencyCoverageClick: TrackingNameType = 'transparency_coverage'
    static readonly NavPhilosophyClick: TrackingNameType = 'nav_philosophy_click';
    static readonly NavContactClick: TrackingNameType = 'nav_contact_click';
    static readonly NavTechnologyClick: TrackingNameType = 'nav_technology_click';
    static readonly WebCenterClick: TrackingNameType = 'web_center_click';
    static readonly NavJobsClick: TrackingNameType = 'nav_jobs_click';
    static readonly NavBlogClick: TrackingNameType = 'nav_blog_click';
    static readonly GsaScheduleClick: TrackingNameType = 'footer_gsaschedule_click';
}

