import React from 'react'
import clsx from 'clsx'
import { BlogNode } from '.'

const availableElements = {
  text: 'p',
  paragraph: 'p',
  list: 'ul',
  listItem: 'li',
  link: 'a',
  bold: 'b',
  heading: 'h2',
  subheading: 'h4',
  image: 'img',
  italics: 'i'
}

type ElementTypes = keyof typeof availableElements

type DefaultClassNameType = { [element in ElementTypes]: string }

const defaultClassNames: Partial<DefaultClassNameType> = {
  image: 'd-block mx-auto',
}

/**
 * Inline Elements inside the `availableElements` object
 */
const inlineElements = [
  availableElements.link,
  availableElements.bold,
  availableElements.listItem,
]

interface RecursiveElementProps {
  nodes?: BlogNode
}

interface GetElementProps extends React.HTMLProps<HTMLElement> {
  elementType: ElementTypes | ''
  children: React.ReactNode
}

/**
 * Generate the valid className for inline and block elements with default calsses
 */
const generateDefaultClassNames = (
  elementType: ElementTypes | '',
  className: string | undefined
): string => {
  const isInlineElement =
    elementType && inlineElements.includes(availableElements[elementType])
  const defaultClassNameOfElemement =
    elementType && defaultClassNames[elementType]

  return clsx(className, {
    'ml-1': isInlineElement,
    'my-4': !isInlineElement,
  }, defaultClassNameOfElemement)
}

/**
 * Creates the React element based on the type of the element.
 */
const DynamicElement = ({
  elementType,
  children,
  className,
  ...props
}: GetElementProps) => {
  console.log({ elementType, children })
  const currentElement = elementType && availableElements?.[elementType]
  return elementType !== 'image'
    ? React.createElement(
        currentElement ? currentElement : React.Fragment,
        {
          className: generateDefaultClassNames(elementType, className),
          ...props,
        },
        children
      )
    : React.createElement(currentElement ? currentElement : React.Fragment, {
        className: generateDefaultClassNames(elementType, className),
        ...props,
      })
}

const RecursiveElement = (props: RecursiveElementProps) => {
  const { nodes } = props

  return (
    <>
      <DynamicElement
        {...nodes?.props}
        elementType={(nodes?.type as ElementTypes) || ''}
      >
        {nodes?.content && `${nodes?.content}`}
        {nodes?.nodes &&
          nodes?.nodes.map((node: BlogNode) => (
            <RecursiveElement nodes={node} />
          ))}
      </DynamicElement>
    </>
  )
}

export default RecursiveElement
